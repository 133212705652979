import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
export interface userInformationType { 
    Id: string,
    TenantId: string,
    Name: string,
    DisplayName: string,
    Email: string,
    RoleId: string,
    Role: string,
    IsCST: string,
    IsRRAS: string,
    Permissions: string,
    iss: string,
    aud: string,
    iat: string,
    nbf: string,
    exp: string,
    userImage: string,
}
const initialState: userInformationType = {
    Id: "",
    TenantId: "",
    Name: "",
    DisplayName: "",
    Email: "",
    RoleId: "",
    Role: "",
    IsCST: "",
    IsRRAS: "",
    Permissions: "",
    iss: "",
    aud: "",
    iat: "",
    nbf: "",
    exp: "",
    userImage: ""
};
const userInformationSlice = createSlice({
    name: 'userInformation',
    initialState,
    reducers: {
        setActiveUserInformation(state : userInformationType ,action : PayloadAction<userInformationType>){            
            state.DisplayName =  action.payload.DisplayName;
            state.Email =  action.payload.Email;
            state.Id =  action.payload.Id;
            state.IsCST =  action.payload.IsCST;
            state.IsRRAS =  action.payload.IsRRAS;
            state.Name =  action.payload.Name;
            state.Permissions =  action.payload.Permissions;
            state.Role =  action.payload.Role;
            state.RoleId =  action.payload.RoleId;
            state.TenantId =  action.payload.TenantId;
            state.aud =  action.payload.aud;
            state.iss =  action.payload.iss;
            state.iat =  action.payload.iat;
            state.exp =  action.payload.exp;
        },
        setActiveUserImage(state : userInformationType ,action : PayloadAction<string>){  
            state.userImage = action.payload; 
        },
        setActiveUserNbf(state : userInformationType ,action : PayloadAction<string>){  
            state.nbf = action.payload; 
        }
    },   
    extraReducers : builder => {
        builder.addCase(PURGE, () => {
            return initialState;
        })
    } 
});
export const {setActiveUserInformation, setActiveUserImage, setActiveUserNbf} = userInformationSlice.actions;
export default userInformationSlice.reducer;