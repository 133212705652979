import React, { useState, useEffect, useCallback } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Modal, Box, Typography, Button, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import DialogWithTwoButton from '../cst/DialogWithTwoButton';
import { GeneratedEmail, AiTextWarning} from '../../utilities/Constants';
import useEditor from './richtextEditorHook';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';


interface ITextEditor { initialValue: string; onChange: (value: string) => void, editorTitle?: string, readonly?: boolean, displayRow?: number, enableRowCustomization?: boolean, isModelEditorReadOnly?: boolean, isGenerateSummaryChecked?: boolean, isContentSummaryTextBox?: boolean, isChangedSummaryContent?: boolean, generateEmail?: () => void, isGeneratePopupsClose?: boolean, setIsGeneratePopupsClose? : (value: boolean) => void, isChangedEmailContent?: boolean,  isEmailVersionManual?: boolean}

const TextEditor: React.FC<ITextEditor> = ({ initialValue, onChange, editorTitle, readonly, displayRow, enableRowCustomization, isModelEditorReadOnly, isGenerateSummaryChecked, isContentSummaryTextBox, isChangedSummaryContent, generateEmail, isGeneratePopupsClose, setIsGeneratePopupsClose, isChangedEmailContent, isEmailVersionManual }) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(ContentState.createFromText(initialValue))
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [forceRenderKey, setForceRenderKey] = useState<string>(Math.random().toString());
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isMoreButtonEnabled, setIsMoreButtonEnabled] = useState(false);
  const [shortEditorState, setShortEditorState] = useState(EditorState.createEmpty());
  const [showScroll, setShowScroll] = useState(true);
  const [enableCustomRow, setEnableCustomRow] = useState<boolean>(enableRowCustomization ?? false);
  const [maxRow, setMaxRow] = useState<number>(displayRow ?? 5);
  const { handleCopy, closeEmailGeneratedPopup, isEmailGeneratedOpen } = useEditor(initialValue, isChangedEmailContent, editorTitle, isEmailVersionManual);
  const updateEditorState = useCallback((newState: EditorState) => {
    setEditorState(newState);
    const rawContentState = convertToRaw(newState.getCurrentContent());
    const text = rawContentState.blocks.map(block => block.text).join('\n');
    onChange(text);
  }, [onChange]);
  useEffect(() => {
    if (initialValue || initialValue === "") {
      const contentState = ContentState.createFromText(initialValue);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
  }, [initialValue]);

    // Undo action
    const undo = () => {
      setForceRenderKey(Math.random().toString());
      const newState = EditorState.undo(editorState);
      updateEditorState(newState);
    };
    // Redo action
    const redo = () => {
        setForceRenderKey(Math.random().toString());
        const newState = EditorState.redo(editorState);
        updateEditorState(newState);
    };

  useEffect(() => {
    const contentState = editorState.getCurrentContent();
    const text = contentState.getPlainText();
    const lines = text.split('\n');
    if (enableCustomRow) {
      if (lines.length > maxRow) {
        const trimmedText = lines.slice(0, maxRow).join('\n');
        const newContentState = ContentState.createFromText(trimmedText);
        setShortEditorState(EditorState.createWithContent(newContentState));
        setIsMoreButtonEnabled(true);
      } else {
        const trimmedText = lines.join('\n');
        const newContentState = ContentState.createFromText(trimmedText);
        setShortEditorState(EditorState.createWithContent(newContentState));
        setIsMoreButtonEnabled(true);
      }
    }
  }, [editorState]);
  const conditionalClose = () => {
    if (isGenerateSummaryChecked && isContentSummaryTextBox && isChangedSummaryContent) {
      setIsOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }
  const closePopup = () => {
    setIsOpen(false);
    setIsModalOpen(false);
  }

  useEffect(() => {
    if(isGeneratePopupsClose){
      closePopup();
    }
    return () => {
      setIsGeneratePopupsClose && setIsGeneratePopupsClose(false);
    };
  }, [isGeneratePopupsClose, setIsGeneratePopupsClose])

  return (
    <div>
      <Editor
        key={forceRenderKey}
        editorState={shortEditorState}
        onEditorStateChange={updateEditorState}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        toolbar={{
          options: ['inline', 'list', 'textAlign'],
          inline: { options: ['bold', 'italic', 'underline'] },
          list: { options: ['unordered', 'ordered', 'indent', 'outdent'] },
          textAlign: { options: ['left', 'center', 'right'] },
        }}
        readOnly={readonly}
      />
      {isMoreButtonEnabled && (
        <div className="editor-actions">
          <Button onClick={() => setIsModalOpen(true)}>
            Read More
          </Button>
          <IconButton onClick={handleCopy} aria-label="copy" title="Click to Copy Text">
            <ContentCopyIcon />
          </IconButton>
        </div>
      )}
      <Modal className='modal-content-preview' open={isModalOpen} onClose={() => setIsModalOpen(false)}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box className="modal-box">
          <div className='modal-heading-preview'>
            <Typography variant="h6" component="h2">
              {editorTitle}
            </Typography>
            <CloseIcon className='close-modal-content-preview' onClick={conditionalClose} />
          </div>
          <div className='text-editor' style={{ position: 'relative' }}>
            <form>
              <fieldset>
                <legend>{editorTitle}*</legend>
                <Editor
                  key={forceRenderKey}
                  editorState={editorState}
                  onEditorStateChange={updateEditorState}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  readOnly={isModelEditorReadOnly}
                  toolbar={{
                    options: ['inline', 'list', 'textAlign'],
                    inline: { options: ['bold', 'italic', 'underline'] },
                    list: { options: ['unordered', 'ordered', 'indent', 'outdent'] },
                    textAlign: { options: ['left', 'center', 'right'] },
                  }}
                  editorStyle={{
                    maxHeight: showScroll ? '400px' : 'auto',
                    overflowY: showScroll ? 'scroll' : 'visible',
                  }}
                />
              </fieldset>
            </form>
            <div className='copy-button'>
                <IconButton onClick={handleCopy} aria-label="copy" title="Click to Copy Text">
                   <ContentCopyIcon />
                </IconButton>
                <IconButton onClick={undo} title="undo" disabled={editorState.getUndoStack().size === 0}>
                   <UndoIcon/>
                </IconButton>
                <IconButton onClick={redo} title="redo"  disabled={editorState.getRedoStack().size === 0}>
                   <RedoIcon/>
                </IconButton>
            </div>
          </div>
        </Box>
      </Modal>
      {isOpen && <DialogWithTwoButton
        dialogOpen={true}
        message={GeneratedEmail}
        yesBtnClick={closePopup}
        noBtnClick={generateEmail ? generateEmail : () => {}}
        dialogClose={closePopup}
        buttonTwoType = "contained"
        buttonTwoText="Yes, Generate"
        buttonOneType="outlined"
        buttonOneText="Cancel"
      />}
      {isEmailGeneratedOpen && <DialogWithTwoButton
        dialogOpen={true}
        message={AiTextWarning}
        yesBtnClick={closeEmailGeneratedPopup}
        dialogClose={closeEmailGeneratedPopup}
        buttonOneText="OK"
        buttonOneType="outlined"
        isCloseShow = {false}
      />}
    </div>
  );
};

export default TextEditor;
