import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { getUsersWithoutPermissions } from "../../networking/apiEndpoints";
import { UserListModelState } from "../../types/components/userModel";

const initialState: UserListModelState = {
    userListModel:{
      status:false,
      message:"",
      result: []    
   } ,
    loading: false,
    error:null,
    responseStatus: 200
  };


export const getUsersWithoutPermissionsAPI = createAsyncThunk<
AxiosResponse,
string,
  {
  rejectValue:AxiosResponse
}
>(
    "getUsersWithoutPermissions/get",
    async (tenantId, thunkApi) => {  
      const response = await axios.get(getUsersWithoutPermissions+ "/" + tenantId, {
        headers:{"Content-Type": "application/json",Authorization:`Bearer ${localStorage.getItem('token')}`},
        validateStatus: function(status){return status < 500;}
      }) ;
  
      if (response.status === 401) {
        return thunkApi.rejectWithValue(response);
      }
  
      return response;         
    }
);

export const getUsersWithoutPermissionsSlice = createSlice({
    name: "getUsersWithoutPermissions",
    initialState,
    reducers: {       
    },
    extraReducers: (builder) => {
        builder.addCase(getUsersWithoutPermissionsAPI.pending, (state) => {
          state.loading = true;
          state.error = '';
        })
        .addCase(getUsersWithoutPermissionsAPI.fulfilled, (state, action) => {
          if(action.payload !== undefined)
          state.userListModel = action.payload.data;
          state.loading = false;
          if(action.payload !== undefined)
            {
              state.responseStatus = action.payload.status;
            }
        }) 
        .addCase(getUsersWithoutPermissionsAPI.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message ?? '';
          if(action.payload !== undefined)
            {
              state.responseStatus = action.payload.status;
            }
        });   
      },
});

export default getUsersWithoutPermissionsSlice.reducer;
